import React from "react";

import Facebook from "./icons/facebook.inline.svg";
import Instagram from "./icons/instagram.inline.svg";
import Twitter from "./icons/twitter.inline.svg";

import styles from "./SocialBlock.module.css";

const socialAccounts = [
  {
    accountType: "instagram",
    accountLogo: Instagram,
    accountLink: "https://www.instagram.com/deliziosaleeds/"
  },
  {
    accountType: "facebook",
    accountLogo: Facebook,
    accountLink: "https://www.facebook.com/deliziosaleeds/"
  },
  {
    accountType: "twitter",
    accountLogo: Twitter,
    accountLink: "https://twitter.com/deliziosaleeds"
  }
];

const SocialBlock = () => {
  return (
      <div className={styles.socialButtonContainer}>
        <ul className={styles.socialButtonWrapper}>
          {socialAccounts.map((value, index) => {
            return (
              <li key={value.accountType} className={styles.logoWrapperOuter}>
                <div className={styles.logoWrapper}>
                  <a target="_blank" rel="noopener noreferrer" href={value.accountLink}>
                    <value.accountLogo />
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

  );
};

export default SocialBlock;
