import React from "react"
import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DeliziosaLogo from "../components/deliziosa.inline.svg"
import NewsletterSignup from "../components/Forms/NewsletterSignup/NewsletterSignup"

import styles from "./index.module.css"
import SocialBlock from "../components/SocialBlock/SocialBlock"
import Logo from "../components/logo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className={styles.mainSection}>
      <Logo />
      <Image />
      <div className={styles.logoWrapper}>
      </div>
      <div className={styles.heroText}>
        <div>
        <h2>Site Under Construction</h2>
        <p className={styles.comingSoon}>howard@consultinghk.co.uk</p>
        </div>
      </div>
      {/* <div className={styles.contentWrapper}>
        <div className={styles.address}>
          <p>138 Harrogate Road <br /> Leeds <br /> LS7 4PF</p>
        </div>
        <div className={styles.mailchimpWrapper}>
          <NewsletterSignup />
        </div>
        <div className={styles.socialWrapper}>
          <SocialBlock />
        </div>
      </div> */}
    </div>
  </Layout>
)

export default IndexPage
