import React, {useState} from "react";
import { Form, Formik, useField, Field } from "formik";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import * as Yup from "yup";

import styles from "./NewsletterSignup.module.css";

const MyCheckbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <label className={styles.checkbox}>
        <input className={styles.check} type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </>
  );
};

const NewsletterSignup = () => {

  const [formState, setFormState] = useState(null)

  const [formMessage, setFormMessage] = useState(null)

  return (
    <>
    <section id="newsletter">
      <div>
            <div className={styles.signupWrapper}>
              <h3>Drop Your Email Here for Updates</h3>
              <Formik
                initialValues={{
                  email: "",
                  acceptedTerms: false,
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid Email Address")
                    .required("Required"),
                  acceptedTerms: Yup.boolean()
                    .required("Required")
                    .oneOf(
                      [true],
                      "You must confirm that you are willing to be contacted by Deliziosa"
                    )
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    addToMailchimp(values.email).then((data) => {
                      const regex = /.+?(?=<a)/;
                      if (data.result==='success') {
                        setFormState(data.result);
                        setFormMessage(data.msg);
                        console.log(data.msg);}
                        else {
                          setFormState(data.result);
                          setFormMessage(regex.exec(data.msg));
                          console.log(data.msg)
                        }
                    })
                    setSubmitting(false);
                  });
                }}
              >
                <Form>
                  <div className={styles.formFields}>
                    <div className={styles.formField}>
                      <Field id="email" name="email" type="email" placeholder="Email Address..."/>
                    </div>
                    <button className={styles.submitButton} type="submit">Submit</button>
                    <MyCheckbox name="acceptedTerms">
                      I agree to being contacted by email by deliziosa
                    </MyCheckbox>
                    {formState && <p style={{gridColumn: 'span 2', marginBottom:'0'}}><span className={styles.formStatus}>{formState} :</span><span className={styles.errorMSG}>{formMessage}</span></p>}
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
    </section>
    </>
  );
};

export default NewsletterSignup;
